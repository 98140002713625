import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Separator from "../../components/Separator";
import "./style.less";

export default class SeparateContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: props.expanded,
    };
  }

  toggleExpandState() {
    if (this.props.collapsable) {
      this.setState({
        expanded: !this.state.expanded,
      });
    }
  }

  getTitle() {
    if (this.props.collapsable)
      return (
        <div className="separate-content--title">
          <FontAwesomeIcon
            icon={this.state.expanded ? faCaretDown : faCaretRight}
            className="icon"
          />
          <span>{this.props.title}</span>
        </div>
      );

    return this.props.title;
  }

  render() {
    return (
      <div className="separate-content">
        <Separator
          title={this.getTitle()}
          onClick={() => this.toggleExpandState()}
          className={this.props.className}
        />
        {this.state.expanded && this.props.children}
      </div>
    );
  }
}
