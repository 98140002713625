import classNames from "classnames";

export default props => {
  const onHeadingClick = e => {
    e.preventDefault();
    props.onClick && props.onClick();
  };

  return (
    <div className="sp-col-12">
      <div className="post-entry">
        <h4
          className={classNames("index-heading", props.className)}
          onClick={onHeadingClick}
        >
          <span>{props.title}</span>
        </h4>
      </div>
    </div>
  );
};
