const { createBookmark, destroyBookmark } = require("../../models/bookmark");
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { faBookmark as faBookmarkSolid } from "@fortawesome/free-solid-svg-icons";
import "./bookmark.less";
import { withI18n } from "../../hooks/use-i18n";

class Bookmark extends React.Component {
  constructor(props) {
    super(props);
  }

  async bookmarkWishlist(e) {
    e.preventDefault();
    await createBookmark(this.props.wishlist);
    this.props.onChange();
  }

  async unbookmarkWishlist(e) {
    e.preventDefault();
    await destroyBookmark(this.props.wishlist);
    this.props.onChange();
  }

  render() {
    const { i18n } = this.props;

    return (
      <>
        <div className="widget laurel_social_widget manage-wishlist bookmark-wishlist">
          <h4 className="widget-title">{i18n.t('web.wishlistsShow.manage.title')}</h4>

          <div className="sidebar-widget">
            <ul className="action-list">
              <li>
                {this.props.isBookmarked ? (
                  <a
                    className="bookmark-delete"
                    onClick={(e) => this.unbookmarkWishlist(e)}
                  >
                    <FontAwesomeIcon icon={faBookmarkSolid} className="icon" />
                    {i18n.t('wishlistMenu.unbookmark.delete')}
                  </a>
                ) : (
                  <a
                    className="bookmark-create"
                    onClick={(e) => this.bookmarkWishlist(e)}
                  >
                    <FontAwesomeIcon icon={faBookmark} className="icon" />
                    {i18n.t('wishlistMenu.bookmark')}
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default withI18n(Bookmark);
