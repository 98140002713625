import Head from "next/head";
import PubSub from "pubsub-js";
import AffiliateNote from "../../../components/AffililateNote";
import Clearer from "../../../components/Clearer";
import Layout from "../../../components/Layout";
import SeparateContent from "../../../components/SeparateContent";
import Sidebar from "../../../components/Sidebar";
import Wish from "../../../components/Wish";
import BookmarkWishlist from "../../../components/Wishlist/bookmark";
import WishlistHeader from "../../../components/WishlistHeader";
import { withI18n } from "../../../hooks/use-i18n";
import { isBookmarked } from "../../../models/bookmark";
import { getUserInfo } from "../../../models/user";
import { BOUGHT, groupByState, OPEN } from "../../../models/wish";
import { getSharedWishlist } from "../../../models/wishlist";
import "../show/styles.less";

class ShowWishlistPublicUuidPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      showForm: false,
      wishes: props.wishes || [],
      wishlist: props.wishlist,
      isBookmarked: props.isBookmarked,
    };

    PubSub.subscribe("page.refresh", (_msg, { user }) => {
      this.setState({ user });
    });
  }

  // async componentDidMount() {
  // }

  hasAffililate() {
    return this.state.wishes[OPEN].concat(this.state.wishes[BOUGHT]).some(
      (w) => !!w.affiliateUrl
    );
  }

  render() {
    const { i18n } = this.props;
    const preGridContent = () => (
      <>
        <WishlistHeader wishlist={this.state.wishlist} />
      </>
    );

    const areas = [
      {
        title: i18n.t("wishBucket.open"),
        wishes: this.state.wishes[OPEN],
        collapsable: false,
        expanded: true,
        className: "open-wishes",
      },
      {
        title: i18n.t("wishBucket.bought"),
        wishes: this.state.wishes[BOUGHT],
        collapsable: true,
        expanded: false,
        className: "bought-wishes",
      },
    ];

    let title = `bitte.kaufen ♥️ Wunschzettel "${this.state.wishlist.title}"`;

    if (this.state.wishlist.owner) {
      title = `${title} von ${this.state.wishlist.owner.username}`;
    }

    return (
      <>
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
        <div className="wishlist-show wishlist-show-public">
          <Layout
            user={this.state.user}
            preGridContent={preGridContent}
            hideMobileLogo
            title={title}
            description={this.state.wishlist.description}
          >
            <div id="main">
              <div className="mobile-only">
                {this.state.user && (
                  <BookmarkWishlist
                    wishlist={this.state.wishlist}
                    isBookmarked={this.state.isBookmarked}
                    onChange={() =>
                      this.setState({ isBookmarked: !this.state.isBookmarked })
                    }
                  />
                )}
              </div>
              <div className="sp-row post-layout">
                <div className="promo-active">
                  <div className="sideslides">
                    <div className="bxslider">
                      {areas.map(
                        (area, index) =>
                          area.wishes &&
                          area.wishes.length > 0 && (
                            <SeparateContent
                              key={index}
                              title={`${area.title} (${area.wishes.length})`}
                              collapsable={area.collapsable}
                              expanded={area.expanded}
                              className={area.className}
                            >
                              {area.wishes.map((wish) => (
                                <div className="sp-col-12" key={wish.uuid}>
                                  <Wish
                                    {...wish}
                                    wishlist={this.state.wishlist}
                                    forOwner={false}
                                    user={this.state.user}
                                  />
                                </div>
                              ))}
                              <Clearer />
                            </SeparateContent>
                          )
                      )}
                      <div className="mobile-only">
                        {this.hasAffililate() && <AffiliateNote />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Sidebar withoutAboutUs withoutNewsletter withLogo>
              <div className="desktop-only">
                {this.state.user && (
                  <BookmarkWishlist
                    wishlist={this.state.wishlist}
                    isBookmarked={this.state.isBookmarked}
                    onChange={() =>
                      this.setState({ isBookmarked: !this.state.isBookmarked })
                    }
                  />
                )}
                {this.hasAffililate() && <AffiliateNote />}
              </div>
            </Sidebar>
          </Layout>
        </div>
      </>
    );
  }
}

export async function getServerSideProps({ query, req, res }) {
  const user = await getUserInfo(req && req.headers.cookie);
  const wishlistData = await getSharedWishlist(query.publicUuid);

  if (!wishlistData) {
    res.writeHead(302, {
      Location: "/wunschzettel",
    });
    return res.end();
  }

  return {
    props: {
      user,
      wishlist: wishlistData,
      wishes: groupByState(wishlistData.wishes),
      cookie: (req && req.headers.cookie) || null,
      isBookmarked: await isBookmarked(
        req && req.headers.cookie,
        query.publicUuid
      ),
    },
  };
}

export default withI18n(ShowWishlistPublicUuidPage);
